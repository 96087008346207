import store from '@/store';
const USER_FEES = store.state.user.userFees;

const calculateOperationFee = ({ type, operation, currency }) => {

  let operationFeeData

  switch (operation) {
    case 'deposit':  
      operationFeeData = USER_FEES.deposit;
    break;
    case 'withdrawal':
      operationFeeData = USER_FEES.withdrawal;
    break;
    case 'internalMovement':
      operationFeeData = USER_FEES.internalMovement;
    break;
    default:
      operationFeeData = USER_FEES.deposit;
    break;
  }

  let feeObject = {};
  // TODO: CHECK on BE why UAH has P2P EUR - doesn't.
  // Add check if operationFeeData[currency][type]['P2P'] exsist.
  const feeData = operationFeeData?.[currency]?.[type]
  if (operationFeeData[currency] && feeData) {
    if (type === 'GATEWAY' && operation === 'deposit' && feeData['P2P']) {
      feeObject = feeData['P2P']?.CARD || feeData['P2P'];
    } else {
      feeObject = feeData;
    }
  }

  return feeObject;
};

export default calculateOperationFee;
