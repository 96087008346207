export default [
  {
    code: "1",
    country: "Afghanistan"
  },
  {
    code: "303",
    country: "Aland Islands"
  },
  {
    code: "6",
    country: "Albania"
  },
  {
    code: "7",
    country: "Algeria"
  },
  {
    code: "8",
    country: "American Samoa"
  },
  {
    code: "9",
    country: "Andorra"
  },
  {
    code: "10",
    country: "Angola"
  },
  {
    code: "240",
    country: "Anguilla"
  },
  {
    code: "241",
    country: "Antarctica"
  },
  {
    code: "12",
    country: "Antigua"
  },
  {
    code: "13",
    country: "Argentina"
  },
  {
    code: "14",
    country: "Armenia"
  },
  {
    code: "242",
    country: "Aruba"
  },
  {
    code: "17",
    country: "Australia"
  },
  {
    "@selected": "selected",
    code: "19",
    country: "Austria"
  },
  {
    code: "20",
    country: "Azerbaijan"
  },
  {
    code: "21",
    country: "Bahamas"
  },
  {
    code: "22",
    country: "Bahrain"
  },
  {
    code: "23",
    country: "Bangladesh"
  },
  {
    code: "24",
    country: "Barbados"
  },
  {
    code: "26",
    country: "Belarus"
  },
  {
    code: "27",
    country: "Belgium"
  },
  {
    code: "28",
    country: "Belize"
  },
  {
    code: "29",
    country: "Benin"
  },
  {
    code: "30",
    country: "Bermuda"
  },
  {
    code: "243",
    country: "Bhutan"
  },
  {
    code: "32",
    country: "Bolivia"
  },
  {
    code: "33",
    country: "Bosnia and Herzegovina"
  },
  {
    code: "34",
    country: "Botswana"
  },
  {
    code: "244",
    country: "Bouvet island"
  },
  {
    code: "35",
    country: "Brazil"
  },
  {
    code: "301",
    country: "British Indian Ocean Territory"
  },
  {
    code: "37",
    country: "Brunei"
  },
  {
    code: "38",
    country: "Bulgaria"
  },
  {
    code: "39",
    country: "Burkina Faso"
  },
  {
    code: "40",
    country: "Burundi"
  },
  {
    code: "41",
    country: "Cambodia"
  },
  {
    code: "42",
    country: "Cameroon"
  },
  {
    code: "43",
    country: "Canada"
  },
  {
    code: "250",
    country: "Cabo Verde"
  },
  {
    code: "45",
    country: "Cayman Islands"
  },
  {
    code: "247",
    country: "Central african republic"
  },
  {
    code: "47",
    country: "Chad"
  },
  {
    code: "48",
    country: "Chile"
  },
  {
    code: "49",
    country: "China"
  },
  {
    code: "251",
    country: "Christmas island"
  },
  {
    code: "245",
    country: "Cocos (keeling) islands"
  },
  {
    code: "52",
    country: "Colombia"
  },
  {
    code: "261",
    country: "Comoros"
  },
  {
    code: "246",
    country: "Congo, DRC"
  },
  {
    code: "54",
    country: "Congo, ROC"
  },
  {
    code: "248",
    country: "Cook islands"
  },
  {
    code: "57",
    country: "Costa Rica"
  },
  {
    code: "58",
    country: "Croatia"
  },
  {
    code: "59",
    country: "Cuba"
  },
  {
    code: "60",
    country: "Cyprus"
  },
  {
    code: "61",
    country: "Czech Republic"
  },
  {
    code: "62",
    country: "Denmark"
  },
  {
    code: "64",
    country: "Djibouti"
  },
  {
    code: "252",
    country: "Dominica"
  },
  {
    code: "66",
    country: "Dominican Republic"
  },
  {
    code: "67",
    country: "Ecuador"
  },
  {
    code: "68",
    country: "Egypt"
  },
  {
    code: "69",
    country: "El Salvador"
  },
  {
    code: "70",
    country: "Equatorial Guinea"
  },
  {
    code: "71",
    country: "Eritrea"
  },
  {
    code: "72",
    country: "Estonia"
  },
  {
    code: "73",
    country: "Ethiopia"
  },
  {
    code: "74",
    country: "North Macedonia"
  },
  {
    code: "254",
    country: "Falkland islands (malvinas)"
  },
  {
    code: "255",
    country: "Faroe islands"
  },
  {
    code: "77",
    country: "Fiji Islands"
  },
  {
    code: "78",
    country: "Finland"
  },
  {
    code: "79",
    country: "France"
  },
  {
    code: "81",
    country: "French Guiana"
  },
  {
    code: "82",
    country: "French Polynesia"
  },
  {
    code: "302",
    country: "French Southern and Antarctic Lands"
  },
  {
    code: "83",
    country: "Gabon"
  },
  {
    code: "84",
    country: "Gambia"
  },
  {
    code: "85",
    country: "Georgia"
  },
  {
    code: "86",
    country: "Germany"
  },
  {
    code: "87",
    country: "Ghana"
  },
  {
    code: "88",
    country: "Gibraltar"
  },
  {
    code: "89",
    country: "Greece"
  },
  {
    code: "90",
    country: "Greenland"
  },
  {
    code: "256",
    country: "Grenada"
  },
  {
    code: "92",
    country: "Guadeloupe"
  },
  {
    code: "93",
    country: "Guam"
  },
  {
    code: "95",
    country: "Guatemala"
  },
  {
    code: "298",
    country: "Guernsey"
  },
  {
    code: "97",
    country: "Guinea"
  },
  {
    code: "96",
    country: "Guinea-Bissau"
  },
  {
    code: "98",
    country: "Guyana"
  },
  {
    code: "99",
    country: "Haiti"
  },
  {
    code: "300",
    country: "Heard Island and McDonald Islands"
  },
  {
    code: "288",
    country: "Holy see (vatican city state)"
  },
  {
    code: "100",
    country: "Honduras"
  },
  {
    code: "101",
    country: "Hong Kong SAR"
  },
  {
    code: "102",
    country: "Hungary"
  },
  {
    code: "103",
    country: "Iceland"
  },
  {
    code: "104",
    country: "India"
  },
  {
    code: "105",
    country: "Indonesia"
  },
  {
    code: "112",
    country: "Iran"
  },
  {
    code: "113",
    country: "Iraq"
  },
  {
    code: "114",
    country: "Ireland"
  },
  {
    code: "296",
    country: "Isle of Man"
  },
  {
    code: "115",
    country: "Israel"
  },
  {
    code: "116",
    country: "Italy"
  },
  {
    code: "117",
    country: "Ivory Coast"
  },
  {
    code: "118",
    country: "Jamaica"
  },
  {
    code: "119",
    country: "Japan"
  },
  {
    code: "297",
    country: "Jersey"
  },
  {
    code: "120",
    country: "Jordan"
  },
  {
    code: "121",
    country: "Kazakhstan"
  },
  {
    code: "122",
    country: "Kenya"
  },
  {
    code: "260",
    country: "Kiribati"
  },
  {
    code: "125",
    country: "Korea"
  },
  {
    code: "124",
    country: "Korea (North)"
  },
  {
    code: "234",
    country: "Kuwait"
  },
  {
    code: "126",
    country: "Kyrgyz Republic"
  },
  {
    code: "263",
    country: "Lao, PDR"
  },
  {
    code: "128",
    country: "Latvia"
  },
  {
    code: "129",
    country: "Lebanon"
  },
  {
    code: "130",
    country: "Lesotho"
  },
  {
    code: "131",
    country: "Liberia"
  },
  {
    code: "132",
    country: "Libya"
  },
  {
    code: "133",
    country: "Liechtenstein"
  },
  {
    code: "134",
    country: "Lithuania"
  },
  {
    code: "135",
    country: "Luxembourg"
  },
  {
    code: "136",
    country: "Macao"
  },
  {
    code: "137",
    country: "Madagascar"
  },
  {
    code: "138",
    country: "Malawi"
  },
  {
    code: "139",
    country: "Malaysia"
  },
  {
    code: "140",
    country: "Maldives"
  },
  {
    code: "141",
    country: "Mali"
  },
  {
    code: "142",
    country: "Malta"
  },
  {
    code: "265",
    country: "Marshall islands"
  },
  {
    code: "144",
    country: "Martinique"
  },
  {
    code: "145",
    country: "Mauritania"
  },
  {
    code: "146",
    country: "Mauritius"
  },
  {
    code: "293",
    country: "Mayotte"
  },
  {
    code: "148",
    country: "Mexico"
  },
  {
    code: "149",
    country: "Micronesia"
  },
  {
    code: "150",
    country: "Moldova"
  },
  {
    code: "151",
    country: "Monaco"
  },
  {
    code: "152",
    country: "Mongolia"
  },
  {
    code: "295",
    country: "Montenegro"
  },
  {
    code: "267",
    country: "Montserrat"
  },
  {
    code: "154",
    country: "Morocco"
  },
  {
    code: "155",
    country: "Mozambique"
  },
  {
    code: "156",
    country: "Myanmar"
  },
  {
    code: "157",
    country: "Namibia"
  },
  {
    code: "270",
    country: "Nauru"
  },
  {
    code: "159",
    country: "Nepal"
  },
  {
    code: "160",
    country: "Netherlands"
  },
  {
    code: "268",
    country: "New caledonia"
  },
  {
    code: "164",
    country: "New Zealand"
  },
  {
    code: "165",
    country: "Nicaragua"
  },
  {
    code: "166",
    country: "Niger"
  },
  {
    code: "167",
    country: "Nigeria"
  },
  {
    code: "271",
    country: "Niue"
  },
  {
    code: "269",
    country: "Norfolk island"
  },
  {
    code: "266",
    country: "Northern mariana islands"
  },
  {
    code: "170",
    country: "Norway"
  },
  {
    code: "171",
    country: "Oman"
  },
  {
    code: "172",
    country: "Pakistan"
  },
  {
    code: "275",
    country: "Palau"
  },
  {
    code: "274",
    country: "Palestinian territory"
  },
  {
    code: "174",
    country: "Panama"
  },
  {
    code: "175",
    country: "Papua New Guinea"
  },
  {
    code: "176",
    country: "Paraguay"
  },
  {
    code: "177",
    country: "Peru"
  },
  {
    code: "178",
    country: "Philippines"
  },
  {
    code: "273",
    country: "Pitcairn"
  },
  {
    code: "179",
    country: "Poland"
  },
  {
    code: "180",
    country: "Portugal"
  },
  {
    code: "181",
    country: "Puerto Rico"
  },
  {
    code: "182",
    country: "Qatar"
  },
  {
    code: "276",
    country: "Réunion"
  },
  {
    code: "184",
    country: "Romania"
  },
  {
    code: "186",
    country: "Russia"
  },
  {
    code: "187",
    country: "Rwanda"
  },
  {
    code: "278",
    country: "Saint helena"
  },
  {
    code: "262",
    country: "Saint kitts and nevis"
  },
  {
    code: "264",
    country: "Saint lucia"
  },
  {
    code: "272",
    country: "Saint pierre and miquelon"
  },
  {
    code: "289",
    country: "Saint vincent & the grenadines"
  },
  {
    code: "304",
    country: "Saint-Barthélemy"
  },
  {
    code: "305",
    country: "Saint-Martin (French part)"
  },
  {
    code: "190",
    country: "San Marino"
  },
  {
    code: "280",
    country: "Sao tome and principe"
  },
  {
    code: "192",
    country: "Saudi Arabia"
  },
  {
    code: "193",
    country: "Senegal"
  },
  {
    code: "294",
    country: "Serbia"
  },
  {
    code: "194",
    country: "Seychelle Islands"
  },
  {
    code: "195",
    country: "Sierra Leone"
  },
  {
    code: "196",
    country: "Singapore"
  },
  {
    code: "197",
    country: "Slovak Republic"
  },
  {
    code: "5",
    country: "Slovenia"
  },
  {
    code: "277",
    country: "Solomon islands"
  },
  {
    code: "3",
    country: "Somalia"
  },
  {
    code: "2",
    country: "South Africa"
  },
  {
    code: "299",
    country: "South Georgia and the South Sandwich Islands"
  },
  {
    code: "306",
    country: "South Sudan"
  },
  {
    code: "198",
    country: "Spain"
  },
  {
    code: "199",
    country: "SriLanka"
  },
  {
    code: "204",
    country: "Sudan"
  },
  {
    code: "205",
    country: "Suriname"
  },
  {
    code: "279",
    country: "Svalbard and jan mayen"
  },
  {
    code: "748",
    country: "Eswatini"
  },
  {
    code: "207",
    country: "Sweden"
  },
  {
    code: "208",
    country: "Switzerland"
  },
  {
    code: "209",
    country: "Syria"
  },
  {
    code: "210",
    country: "Taiwan"
  },
  {
    code: "211",
    country: "Tajikistan"
  },
  {
    code: "212",
    country: "Tanzania"
  },
  {
    code: "213",
    country: "Thailand"
  },
  {
    code: "284",
    country: "Timor-leste"
  },
  {
    code: "215",
    country: "Togo"
  },
  {
    code: "283",
    country: "Tokelau"
  },
  {
    code: "285",
    country: "Tonga"
  },
  {
    code: "218",
    country: "Trinidad and Tobago"
  },
  {
    code: "219",
    country: "Tunisia"
  },
  {
    code: "220",
    country: "Turkey"
  },
  {
    code: "221",
    country: "Turkmenistan"
  },
  {
    code: "281",
    country: "Turks and caicos islands"
  },
  {
    code: "286",
    country: "Tuvalu"
  },
  {
    code: "229",
    country: "U.S.Virgin Islands"
  },
  {
    code: "224",
    country: "Uganda"
  },
  {
    code: "225",
    country: "Ukraine"
  },
  {
    code: "226",
    country: "United Arab Emirates"
  },
  {
    code: "227",
    country: "United Kingdom"
  },
  {
    code: "228",
    country: "United States"
  },
  {
    code: "230",
    country: "Uruguay"
  },
  {
    code: "287",
    country: "US minor outlying islands"
  },
  {
    code: "18",
    country: "Uzbekistan"
  },
  {
    code: "291",
    country: "Vanuatu"
  },
  {
    code: "535",
    country: "Bonaire, Sint Eustatius and Saba"
  },
  {
    code: "531",
    country: "Curaçao"
  },
  {
    code: "534",
    country: "Sint Maarten (Dutch part)"
  },
  {
    code: "55",
    country: "Venezuela"
  },
  {
    code: "233",
    country: "Viet Nam"
  },
  {
    code: "290",
    country: "Virgin islands, british"
  },
  {
    code: "292",
    country: "Wallis and futuna"
  },
  {
    code: "253",
    country: "Western sahara"
  },
  {
    code: "235",
    country: "Samoa"
  },
  {
    code: "236",
    country: "Yemen"
  },
  {
    code: "238",
    country: "Zambia"
  },
  {
    code: "239",
    country: "Zimbabwe"
  }
]