<template>
  <div class="flex md:block lg:flex items-center justify-between m-[0_0_12px] font-[500] text-[14px] leading-[16px] uppercase text-left md:text-[21px] md:leading-[25px]">
    <slot />
  </div>
</template>

<script>
export default {
  name: "WalletMethodTitle"
}
</script>