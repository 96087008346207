<template>
  <div class="PassVerification">
    <div class="PassVerification_wrapper">
      <div class="PassVerification_container">
        <div class="PassVerification_head">
          <InfoPseudoIcon color="white" />Please pass the identity verification to use this {{ operation }} method
        </div>
        <button class="PassVerification_btn" @click="handleClick">pass verification</button>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoPseudoIcon } from '@/assets/icons/arrows';
export default {
  components: {
    InfoPseudoIcon,
  },
  props: {
    operation: {
      type: String,
      default: 'deposit',
    },
  },
  methods: {
    handleClick() {
      this.$router.push({ name: 'verification' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './PassVerification.scss';
</style>
