export const walletDepositAndWithdraw = {
  methods: {
    computedFeeHelper(feeObject) {
      if (feeObject) {
        const { percent_fee, static_fee } = feeObject;
        const currency = this.currency
        if (static_fee == undefined || percent_fee == undefined) return '-';
        if (percent_fee && static_fee) {
          return percent_fee + '% + ' + static_fee + ' ' + currency;
        } else if (percent_fee && !static_fee) {
          return percent_fee + '%';
        } else if (!percent_fee && static_fee) {
          return static_fee + ' ' + currency;
        }      
        return '0 ' + currency;
      } else {
        return '-';
      }
    },
  },
};
