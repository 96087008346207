<template>
  <div class="steps-bar grid justify-center border-b-[1px] border-b-blue-500/[0.1] pb-[11px] mb-[25px]">
    <div
      v-for="currentStep in stepsBar"
      :key="currentStep.stepNumber"
      class="flex items-center justify-center rounded-[6px] bg-white/[0.034] border border-white/[0.1] min-h-[46px] relative"
    >
      <img
        :src="getImg(currentStep.iconUrl)"
        class="lg:mr-[10px] object-scale-down w-[20px] h-[20px] 2xl:w-[30px] 2xl:h-[30px]"
      >
      <div class="hidden lg:block md:text-left">
        <div class="uppercase tracking-[0.4px] leading-[1] text-[10px] text-[#9395AB] mb-[3px]">{{ currentStep.stepNumber }}.Step</div>
        <div class="text-[11px] 2xl:text-[14px] leading-[16px]">{{ currentStep.stepName }}</div>
      </div>
      <span
        class="h-[1px] absolute left-0 bottom-[-13px]"
        :class="{
          'bg-blue-500 w-1/2': activeStep === currentStep.stepNumber,
          'bg-blue-500 w-full': activeStep > currentStep.stepNumber,
        }"
      >
        <span
          v-if="activeStep === currentStep.stepNumber"
          class="absolute z-[999] right-0 bottom-[-3px] w-[6px] h-[6px] rounded-full bg-blue-500 shadow-stepActive"
        >
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsBar",
  props: {
    activeStep: {
      type: Number,
      required: true
    },
    stepsBar: {
      type: Array
    }
  },
  methods: {
    getImg(url) {
      try {
        return require(`@/assets/icons/${ url }`);
      } catch (error) {
        return '';
      }
    },
  }
};
</script>
<style lang="scss" scoped>

.steps-bar {
  grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
  grid-gap: 10px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 30px;
  }
}
</style>